<template>
  <div class="home-page">
    <div class="header">
      <div class="header-container">
        <div class="header-left">
          <div class="logo">
            <img src="//jz.yofish.com/img/logo.png" alt="" />
            <span>有鱼记账</span>
          </div>
        </div>
        <div class="right">
          <div class="header-right" @click="recordAction">
            <span>网页记账</span>
          </div>
          <div class="header-right" @click="jumpAction">
            <span>购买会员</span>
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="main-bg module-1">
        <img
      src="//download-cos.yofish.com/yofish-gongjushiyebu/2023092109545811-yyb.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Home',
  computed: {
  },
  mounted() {
  },
  methods: {
    jumpAction() {
      const cuserId = window.localStorage.getItem('cuserid');
      const { origin } = window.location;
      window.sessionStorage.setItem('backTo', 'vip1');
      window.location.href = `${origin}/activity/package/home`;
    },
    recordAction() {
      window.location.href = '//jz-h5.yofish.com/jzweb/record';
    },
  },
};
</script>

<style lang="scss" scoped>
.home-page {
  width: 750px;
  margin: auto;
  .header-container {
    width: 95%;
    height: 65px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .header-container .logo {
    display: flex;
    align-items: center;
  }
  .header-container .logo img {
    width: 50px;
    height: 50px;
  }
  .header-container .logo span {
    position: relative;
    font-size: 28px;
    font-weight: 500;
    padding-right: 24px;
    padding-left: 24px;
  }
  .header-left {
    display: flex;
    align-items: center;
  }
  .header-right {
    font-size: 28px;
    cursor: pointer;
  }
  .main-bg {
    width: 100%;
    position: relative;
  }
  .main-bg img {
    width: 100%;
  }
  .right {
    display: flex;
    .header-right:first-child {
      margin-right: 15px;
    }
  }
}
@media only screen and (min-width: 751px) {
  .home-page {
    .header-right {
      font-size: 20px;
      &:first-child {
        margin-right: 25px;
      }
    }
  }
}
</style>
